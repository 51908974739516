<template>
	<div class="notice">
		<div class="my-title">
			<div style="text-indent: 0px; line-height: none">
				悦融易银行合作告知函
			</div>
		</div>
		<div style="padding: 0 30px">
			<h1
				style="
					text-align: center;
					font-size: 18px;
					font-weight: 800;
					padding: 10px 0;
				"
			>
				悦融易银行合作告知函
			</h1>
			<h2 style="font-weight: 800">尊敬的银行朋友：</h2>
			<p>
				悦融易是一家专注中小企业的金融服务科技公司。公司秉承“客户第一、合作共生、诚信专业”的核心价值观，为中小企业提供专业的融资顾问服务，为银行提供合适的客户。
			</p>
			<p>
				为更好的服务客户，维护各方利益，确保合作顺利有序开展，特就双方后续合作，做如下告知
			</p>
			<p>
				1、我司在对客户进行必要的尽调后，根据银行的授信政策和产品，推荐合适的客户。希望贵行能快速处理我司推荐的客户需求，及时给予反馈，并在后续的服务时效上予以大力支持。
			</p>
			<p>
				2、请对我司推荐客户信息进行严格保密，未经我司同意，请勿对外披露。如有业务合作兴趣，请与我司及时联系反馈。
			</p>
			<p>
				3、我司正式推荐的客户（报告取消脱敏的客户），均已与我司签署相关服务协议，且已签署《融资授权授权书》。<span
					style="font-weight: 800"
					>我司将代表客户与贵行进行沟通交流。</span
				>
			</p>
			<p>
				4、我司向贵行推荐客户时，请先查询该客户在贵行近期是否有过贷款申请记录，是否存在需要调整贷款管户权的情形，如有请及时反馈我司，由我司先行协调客户，避免因管户权争议对合作带来影响。
			</p>
			<p>
				5、如因银行政策变化，或客户资质等原因，暂时无法合作的，请及时向我司反馈，并将客户资料退回我司或直接退回客户，避免出现客户信息泄漏等损害客户利益的情况发生。
			</p>
			<p>
				6、合作过程中，客户融资进展情况请与我司及时反馈沟通，有关客户的重要事项，例如<span
					style="font-weight: 800"
					>融资方案的变更或调整</span
				>等，请务必先告知我司，以便于我司履行与客户合同义务，发挥融资顾问价值。避免出现由于我司信息缺失而产生的顾问服务不到位的情形。
			</p>
			<p>
				7、在贵行与我司推荐客户达成合作关系之后，请贵行适当维护我司权益。对于未委托我司提供<span
					style="font-weight: 800"
					>贷款续期</span
				>和<span style="font-weight: 800">贷后管理服务</span
				>的客户，建议推荐我司贷后管理和贷款续期服务，也有助于贵行与客户的业务合作更加融洽！
			</p>
			<p>
				8、贵行如遇无法满足需求的中小企业客户，也非常希望能够向客户推荐我司的服务产品，我司将竭诚为中小企业客户提供专业高效的服务。共同为缓解中小企业融资难的问题做出努力。
			</p>
			<p>如有疑问，欢迎与对接产品经理联系，谢谢！</p>
			<p>顺祝商祺！</p>
			<p style="text-align: right">深圳市悦融易数据科技有限公司</p>
			<p style="text-align: right; margin-right: 50px">2022年2月22日</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'notice',
	data() {
		return {};
	},
	methods: {},
	created() {},
	components: {},
};
</script>
<style lang="scss" scoped>
.notice {
	width: 100%;
	height: 100%;
}
p {
	line-height: 30px;
	text-indent: 30px;
}
</style>
